<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<a-input class="woyaou-input" prefix="券名称" v-model="form.name" :suffix="form.name.length + '/20'" :maxLength="20"
					 placeholder="请输入项目名称"></a-input>
				</div>
				
				<div class="mt24">
					<div class="flex alcenter">
						<div class="ft14 ftw500 cl-info">分类</div>
						<div class="ml16">
							<a-radio-group v-model="form.type_id">
								<a-radio :value="1">
									普通券
								</a-radio>
								<a-radio :value="2">
									新人券
								</a-radio>
								<a-radio :value="3">
									会员券
								</a-radio>
								<a-radio :value="4">
									邀请券
								</a-radio>
							</a-radio-group>
						</div>
					</div>
				</div>
				
				<div class="mt10" v-if="form.type_id==2">
					 <a-alert message="新人券用于新人注册后自动发放至账号" banner  />
				</div>
				
				<div class="mt10" v-if="form.type_id==4">
					 <a-alert message="邀请券用于邀请新人后邀请人与被邀请人同时获取随机邀请券" banner  />
				</div>

				<div class="mt24 coupon-index-modal-time">
					<div style="position: relative;">
						<a-range-picker @change="changeReleaseTime" v-model="form.release_time" valueFormat="YYYY-MM-DD" />
						<div class="input-addon-before">发放时间</div>
					</div>
				</div>

				<div class="mt24">
					<div class="flex space">
						<div>
							<div class="pl15 ft14 ftw500 cl-info">优惠金额</div>
							<div class="mt10">
								<a-input-number style="width: 225px;" placeholder="请输入" :precision="2" :min="0" v-model="form.coupon_money" />
							</div>
						</div>


						<div>
							<div class="pl15 ft14 ftw500 cl-info">满减条件</div>
							<div class="mt10">
								<a-input-number style="width: 225px;" placeholder="请输入" :precision="2" :min="0" v-model="form.money_limit" />
							</div>
						</div>
					</div>
				</div>


				<div class="mt24 coupon-index-modal-time">
					<div style="position: relative;">
						<a-range-picker @change="changeValidityTime" v-model="form.validity_time" valueFormat="YYYY-MM-DD"/>
						<div class="input-addon-before">使用有效期</div>
					</div>
				</div>
				
				<div v-if="form.type_id == 1 || form.type_id == 3">
					<div class="mt24">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">配额：发券数量</div>
							<div class="ml16">
								<a-input-number v-model="form.quota" style="width: 220px;" placeholder="请输入配额" :precision="0" :min="0"></a-input-number>
							</div>
						</div>
					</div>
					
					<div class="mt24">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">是否可用积分兑换</div>
							<div class="ml16">
								<a-radio-group v-model="form.is_integral_exchange">
									<a-radio :value="1">
										是
									</a-radio>
									<a-radio :value="0">
										否
									</a-radio>
								</a-radio-group>
							</div>
						</div>
					</div>
					
					<div class="mt24" style="position: relative;width: 220px;" v-if="form.is_integral_exchange==1">
						<a-input-number style="width: 220px;" placeholder="请输入" :precision="0" :min="0" v-model="form.exchange_integral"/>
						<div class="edit-coupon-addon">积分</div>
					</div>
				</div>

			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			coupon_id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					name: '',
					type_id:1,
					release_time: [],
					validity_time: [],
					coupon_money: 0,
					money_limit: 0,
					is_integral_exchange:0,
					exchange_integral:0,
					quota:0,
				}
			}
		},
		computed: {
			getTitle() {
				if (this.coupon_id == 0) {
					return "添加优惠券";
				} else {
					return "编辑优惠券"
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showCoupon',{
					coupon_id:this.coupon_id,
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			changeReleaseTime(value){
				this.form.release_time=value;
			},
			
			changeValidityTime(value){
				this.form.validity_time=value;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveCoupon',{
					coupon_id:this.coupon_id,
					name:this.form.name,
					type_id:this.form.type_id,
					release_time:this.form.release_time,
					validity_time:this.form.validity_time,
					coupon_money:this.form.coupon_money,
					money_limit:this.form.money_limit,
					is_integral_exchange:this.form.is_integral_exchange,
					exchange_integral:this.form.exchange_integral,
					quota:this.form.quota,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.refreshPage('/coupon'); // 如果在很多地方使用的组件 表单操作成功后要去到对应的页面 就可以调用 refreshPage
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},

		}

	}
</script>

<style>
	.coupon-index-modal-time .ant-calendar-picker {
		width: 100%;
	}

	.coupon-index-modal-time .ant-input {
		padding-left: 60px;
		height: 40px;
	}


	.coupon-index-modal-time .input-addon-before {
		height: 40px;
		padding-left: 20px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: right;
		line-height: 40px;

		font-size: 14px;
		font-weight: 500;
		color: #656A75;
	}
	
	.edit-coupon-addon{
		position: absolute;
		top:0px;
		right: 26px;
		height: 32px;
		line-height: 32px;
		
		font-size: 14px;
		font-weight: 500;
		color: #656A75;
	}
</style>
