<template>
	<div>
		<a-spin :spinning="loading">
			<div class="bg-w pd30" style="min-height: 700px;">
				<div class="flex wrap" v-if="datas.length>0">
					<div class="coupon-index-item" v-for="(item,index) in datas">
						<div class="flex space alcenter">
							<div>
								<div class="text-over4 ft14 ftw600 cl-black">{{item.name}}</div>
								<div class="mt15 ft12 ftw400 cl-notice text-over4">满{{item.money_limit}}元可用</div>
								<div class="mt10 ft12 ftw400 cl-info text-over4">有效期：{{item.valid_bg_time}}-{{item.valid_end_time}}</div>
							</div>
							
							<div>
								<div class=" flex end">
									<div class="flex alcenter">
										<div class="ft12 ftwblod cl-price">¥</div>
										<div class="ft24 ftwblod cl-price">{{item.coupon_money}}</div>
									</div>
								</div>
								
								<div class="mt10 flex end">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="edit(index)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑优惠卷</span>
												</a>
											  </a-menu-item>
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="undercarriage(index)">
													<i class="iconfont ft14 iconxiajia"></i>
													<span class="ml10">下架优惠卷</span>
												</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="mt20 flex center" v-else>
					<a-empty/>
				</div>
			
				<div class="flex center mt20">
					<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
				</div>
				
				<div v-if="editCouponVisible">
					<edit-coupon :visible="editCouponVisible" :coupon_id="coupon_id" @cancel="cancelEditCoupon" @ok="okEditCoupon"></edit-coupon>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	import editCoupon from './components/layout/modal/editCoupon.vue';
	export default{
		mixins:[listMixin],
		components:{
			editCoupon,
		},
		data(){
			return {
				loading:false,
				editCouponVisible:false,
				coupon_id:0,
				datas:[],
			}
		},
		created() {
			
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getCouponList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					status:1,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			undercarriage(index){
				this.$confirm({
					title:'确认下架这个优惠券吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/undercarriageCoupon',{
								coupon_id:this.datas[index].coupon_id,
							}).then(res=>{
								this.$message.success('下架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			edit(index){
				this.coupon_id=this.datas[index].coupon_id;
				this.editCouponVisible=true;
			},
			cancelEditCoupon(){
				this.editCouponVisible=false;
			},
			okEditCoupon(){
				this.editCouponVisible=false;
				this.getLists();
			}
		}
	}
</script>

<style>
	@media screen and (max-width: 1100px) {
		.coupon-index-item{
			width: 99%;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom:1%;
			padding: 20px;
		}
	}
	
	
	@media screen and (min-width: 1101px) and (max-width: 1450px) {
		.coupon-index-item{
			width: 49%;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom:1%;
			padding: 20px;
		}
	}
	
	@media screen and (min-width:1451px){
		.coupon-index-item{
			width: 32%;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom:1%;
			padding: 20px;
		}
	}
</style>